import "core-js/modules/es.array.push.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "LoginOut"
};
import { onMounted } from "vue";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import CacheUserInfo from "@/utils/Cache/CacheUserInfo";
import Common_Router from "@/utils/Common/Common_Router";
export default {
  __name: 'LoginOut',
  setup(__props) {
    onMounted(() => {
      VantMsgLib.notify(1, 'Log out...');
      let info = CacheUserInfo.getUserInfo();
      CacheUserInfo.setUserInfo(null, null, null);
      setTimeout(() => {
        Common_Router.push({
          name: 'Common.Login',
          params: {
            version: info.version === 1 ? 'c' : 'y'
          }
        });
      }, 500);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1);
    };
  }
};